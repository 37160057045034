(function ($) {
    $(document).ready(function () {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $(document).on('click', '.add-to-bookmark', function (e) {
            e.preventDefault();
            let item_id = $(this).data('id');
            let entity = $(this).data('entity');

            $.ajax({
                url: "/ajax/bookmark", method: "POST", data: {"item_id": item_id, "entity": entity},
                success: function (data) {
                    if (data.bookmarked === true) {
                        $('#'+entity+'_'+item_id).show();
                    }
                    else if (data.bookmarked === false) {
                        $('#'+entity+'_'+item_id).hide();
                    }
                }
            });
        });

    });
})(jQuery);
